import {
    PieChart,
    Pie,
    ResponsiveContainer,
    Cell,
    LineChart,
    Line, 
    CartesianGrid,
    YAxis,
    XAxis,
    Tooltip,
    Legend,
    } from "recharts";



export default function DeviceChart({data, timedData}) {
    const COLORS = ['#ff6955', '#ffcf39', '#ffae55', '#a5be6d', '#88aa3d'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
          let d = payload[0].payload;
          return (
              <div>
                  <p className="label">{`device : ${d.name}s`}</p>
                  <p className="desc">{`count : ${d.value}`}</p>
              </div>
          );
      }
    
      return null;
    };

    const CustomDeviceTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
          let d = payload[0].payload;
          console.log(d)
          return (
              <div>
                  {Object.entries(d).map(([e,i]) => {
                    return(<p key={e+'-tooltip'}>{`${e}: ${i}`}</p>)
                  })}
              </div>
          );
      }
    
      return null;
    };

    return (
      <div style={{margin: '20px 0px 40px 0px', display: 'flex',width: '100%', justifyContent:'flex-start', position:'relative'}}>
      <div style={{width: 760}}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={timedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" unit="s"/>
            <YAxis />
            <Legend />
            {Object.keys(timedData[0]).map((key, index) => {
              if(key !== 'time')
                return(<Line key={key +'-line'} type="monotone" dataKey={key} strokeWidth="2" stroke={COLORS[index % COLORS.length]} />)
              return('')
            })}
            <Tooltip content={CustomDeviceTooltip}/>
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={{width: 440}}>
      <div>{data.map((d,index) => {
        return(<div key={'device-' + index} style={{fontSize: '12px', textAlign: 'left'}}><span style={{color: COLORS[index % COLORS.length]}}>&#9632; </span>{d.name} : {d.value}</div>)
      })}</div>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart width={400} height={400}>
          <Tooltip content={CustomTooltip}/>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={200}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      </div>
      
      </div>
    );
}
