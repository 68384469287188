import React from "react";
import {
   ComposedChart,
   Area,
   YAxis,
   XAxis,
   Tooltip,
   Legend,
   Line
   } from "recharts";

   export default function AreaRechartComponent({time, data, setTimeFunc}) {


    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            let d = payload[0].payload;
            return (
                <div>
                    <p className="label">{`time : ${d.time}s`}</p>
                    <p className="desc">{`count : ${d.count}`}</p>
                    <p className="desc">{`average : ${d.average}`}</p>
                </div>
            );
        }
      
        return null;
      };

    const handleClick = (payload) => {
        setTimeFunc(payload.activeLabel)
    }

    return (
    <div style={{margin: '20px 0px 40px 0px', display: 'flex', justifyContent:'flex-start', position:'relative'}}>
        <ComposedChart
            onClick={handleClick}
            width={740}
            height={260}
            data={data.map(d => {
                d.progress = (d.time / time) >= 1 ? d.count : 0;
                d.episode = d.count;
                d.program = d.average;
                return d;
            })}
            margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
        <XAxis dataKey="time" unit="s"/>
        <YAxis />
        <Tooltip  content={<CustomTooltip />} />
        <Legend />
        <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="episode"
            stroke="orange"
            strokeWidth="2"
            fillOpacity={1}
            fill="#ffae55"/>
        <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="progress"
            stroke="transparent"
            fillOpacity={0.3}
            fill="white"/>
        <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="program"
            strokeWidth="2"
            stroke="#ff6955"
            fill="transparent"/>
        </ComposedChart>
        <div style={{flexGrow:2}}>

        </div>
        <div style={{justifyItems: 'flex-end', alignSelf: 'baseline',flexGrow:1, marginTop: '-60px'}}>
            <img src="./logos2.png" alt="logos" width="200"/>
        </div>
        </div>
    )
}
