import React, {useEffect, useState} from 'react'
import './App.css';
import AreaChart from './components/AreaChart';
import URVideo from './components/URVideo';
import Numbers from './components/Numbers';
import Data from './Data.js'
import DayChart from './components/DayChart';
import DeviceChart from './components/DeviceChart';



function App() {
  const [time, setTime] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(Data());
  const [deviceData, setDevices] = useState([]);
  const [timedData, setTimedDevices] = useState([]);
  const [numbers, setNumbers] = useState();


  const calculateDeviceTotal = function(_data) {
      let _devices = {}
      for (let i = 0; i < _data.length; i++) {
        const platforms = _data[i].platforms;
        Object.entries(platforms).forEach(([k, v]) => {
          if(v.count) {
            if(!_devices[k]) {
              _devices[k] = {name: k, value: v.count};
            }
            else {
              _devices[k] = {name: k, value: _devices[k].value + v.count}
            }
          }
        })
        
      }
      const d = Object.values(_devices).sort((a,b) => a.value > b.value ? -1 : 1);
      return d;
  }

  const selectedMediaChanged = (media_id) => {
    fetch('https://peach.ebu.io/api/v1/zzebu/seur_graph?media_id=' + media_id)
      .then((response) => response.json())
      .then((json) => {
        console.log(json)
        let items = json.result.items;
        let  ConvertSectoDay = function(n) {
          var day =parseInt( n / (24 * 3600));
   
          n = n % (24 * 3600);
          var hour = parseInt(n / 3600);
   
          n %= 3600;
          var minutes = n / 60;
   
          n %= 60;
          var seconds = n;
   
          day = (day > 0) ? day +  "d " : "";
          hour = (hour > 0) ? hour.toFixed() + "h " : "";
          minutes = (minutes > 0) ? minutes.toFixed() + "m " : ""; 
          seconds = seconds > 0 ? seconds.toFixed() + "s " : "";
          return day + hour + minutes + seconds;
        }
        setSelectedMedia(media_id)
        setData(items.graph)
        setDevices(calculateDeviceTotal(items.graph))
        setNumbers({
          average_per_program: ConvertSectoDay(Math.round(items.average_per_program)), 
          average_per_user: ConvertSectoDay(Math.round(items.average_per_user)), 
          total_users: items.total_users,
          total_viewership: ConvertSectoDay(items.total_viewership),
          time_activity: items.time_activity.map((d, i) => {return {time: i > 9 ? i +':00' : '0' + i + ':00', value: d}}),
          day_activity: items.day_activity
        })
        setTimedDevices(items.graph.map((d,i) => {
          Object.entries(d.platforms).forEach(([e,i]) => {
            let z = d.platforms[e];
            d.platforms[e] = z.count || 0;
          })
          d.platforms.time = i * 20;
          return d.platforms;
        }))
      })
  }

  const MEDIA_IDS = ['224581', '228036', '228562']
  const [selectedMedia, setSelectedMedia] = useState(Data());

  const [dataLoaded, setDataLoaded] = useState(false)

  useEffect(() => {
      if(!dataLoaded){
        let media_id = '228036';
        fetch('https://peach.ebu.io/api/v1/zzebu/seur_graph?media_id=' + media_id)
          .then((response) => response.json())
          .then((json) => {
            let items = json.result.items;
            let  ConvertSectoDay = function(n) {
              var day =parseInt( n / (24 * 3600));
       
              n = n % (24 * 3600);
              var hour = parseInt(n / 3600);
       
              n %= 3600;
              var minutes = n / 60;
       
              n %= 60;
              var seconds = n;
       
              day = (day > 0) ? day +  "d " : "";
              hour = (hour > 0) ? hour.toFixed() + "h " : "";
              minutes = (minutes > 0) ? minutes.toFixed() + "m " : ""; 
              seconds = seconds > 0 ? seconds.toFixed() + "s " : "";
              return day + hour + minutes + seconds;
            }
            setSelectedMedia(media_id)
            setData(items.graph)
            setDevices(calculateDeviceTotal(items.graph))
            setNumbers({
              average_per_program: ConvertSectoDay(Math.round(items.average_per_program)), 
              average_per_user: ConvertSectoDay(Math.round(items.average_per_user)), 
              total_users: items.total_users,
              total_viewership: ConvertSectoDay(items.total_viewership),
              time_activity: items.time_activity.map((d, i) => {return {time: i > 9 ? i +':00' : '0' + i + ':00', value: d}} ),
              day_activity: items.day_activity
            })

            setTimedDevices(items.graph.map((d,i) => {
              Object.entries(d.platforms).forEach(([e,i]) => {
                let z = d.platforms[e];
                d.platforms[e] = z.count || 0;
              })
              d.platforms.time = i * 20;
              return d.platforms;
            }))


          })
      }
      setDataLoaded(true);
  }, [dataLoaded])

  return (
    <div className="App" style={{ maxWidth: 1200, margin: '40px auto',}}>
        <aside style={{position: 'fixed', top: '40px', left: 20, width: '110px', padding: '16px 8px', borderTopRightRadius: '5px'}}>
          <div onClick={() => selectedMediaChanged('224581')} style={{cursor: 'pointer'}}>
            <img src='./224581-poster.png' alt="ur" width='100' style={{borderRadius: '5px', marginBottom:'10px'}} />
          </div>
          <div onClick={() => selectedMediaChanged('228036')} style={{cursor: 'pointer'}}>
            <img src='./228036-poster.png' alt="ur" width='100' style={{borderRadius: '5px', marginBottom:'10px'}} />
          </div>
          <div onClick={() => selectedMediaChanged('228562')} style={{cursor: 'pointer'}}>
            <img src='./228562-poster.png' alt="ur" width='100' style={{borderRadius: '5px', marginBottom:'10px'}} />
          </div>

        </aside>
        <URVideo selectedMedia={selectedMedia} time={time} setTimeFunc={(t) => setTime(t)}/>
        <h1 style={{textAlign: 'left', margin: '0px 20px', lineHeight: '2em'}}>Consumption of media</h1>
        <AreaChart data={data} time={time} setTimeFunc={(t) => setTime(t)}/>
        {numbers && <Numbers {...numbers}/>}
        {numbers && numbers.time_activity.length > 0 && <DayChart {...numbers}/>}
        {deviceData &&  deviceData.length > 0 && <>
          <h1 style={{textAlign: 'left', margin: '30px 20px 20px', lineHeight: '2em'}}>
              Consumption per device
          </h1>
          <DeviceChart timedData={timedData} data={deviceData} />
        </>}
        
    </div>
  );
}

export default App;
