
export default function Numbers({average_per_program, average_per_user, total_users, total_viewership}) {


    return(
        <>
        <h1 style={{textAlign: 'left', margin: '0px 20px', lineHeight: '2em'}}>
            Consumption numbers
        </h1>
        <div style={{margin: '20px 0px 40px -20px', display: 'flex', justifyContent:'space-evenly', fontSize:'50px', fontWeight: 'bold', color:'#2b2626',  position:'relative'}}>
            <div>
                {Math.round(total_users)}
                <div style={{fontSize: 12}}>Total users</div>
            </div>
            <div>{average_per_program}
            <div style={{fontSize: 12}}>Average consumption per program</div>
            </div>
            <div>{average_per_user}
            <div style={{fontSize: 12}}>Average consumption per user</div>
            </div>
            <div>{total_viewership}
            <div style={{fontSize: 12}}>Total consumption</div>
            </div>
        </div>
        </>
    )
}