import React, {useState} from 'react'
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    Cell,
    YAxis,
    CartesianGrid,
    XAxis,
    Line,
    ComposedChart
    } from "recharts";


export default function DayChart({time_activity, day_activity}) {

const data = time_activity;

const [activeIndex, setActiveIndex] = useState(13);

const handleClick = (data, index) => {
  setActiveIndex(index);
};

const ConvertSectoDay = function(n) {
  var day =parseInt( n / (24 * 3600));

  n = n % (24 * 3600);
  var hour = parseInt(n / 3600);

  n %= 3600;
  var minutes = n / 60;

  n %= 60;
  var seconds = n;

  day = (day > 0) ? day +  "d " : "";
  hour = (hour > 0) ? hour.toFixed() + "h " : "";
  minutes = (minutes > 0) ? minutes.toFixed() + "m " : ""; 
  seconds = seconds > 0 ? seconds.toFixed() + "s " : "";
  return day + hour + minutes + seconds;
}


const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const customLable = function({ x, y, stroke, value }){
  return (
    <text x={x} y={y} dy={-4}  fill={stroke} fontSize={10} textAnchor="left">
      {value}
    </text>
  );
}
return(
  <div style={{display: 'flex'}}>
      <div style={{ width: '760px', textAlign: 'left',marginTop: '20px', fontSize: '12px'}}>
        <h1 style={{textAlign: 'left', margin: '0px 20px', lineHeight: '2em'}}>
            Consumption per hour during day
        </h1>
        <div style={{marginLeft: 20}}>
        <p>Click each rectangle </p>
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart width={150} height={300} data={data}>
            <XAxis dataKey="time"/>
            <YAxis units="s"/>
            <Bar dataKey="value" onClick={handleClick} background={{ fill: '#eee' }}>
              {data.map((entry, index) => (
                <Cell cursor="pointer" fill={index === activeIndex ? '#ff6955' : '#ffaf56'} key={`cell-${index}`} />
              ))}
            </Bar>
            <Line type="monotone" strokeWidth={2} dataKey="value" stroke="#ff7300" />
            <CartesianGrid strokeDasharray="3 3" />
          </ComposedChart>
          </ResponsiveContainer>
        <p className="content"><b>{`Consumption per hour "${time_activity[activeIndex].time}": ${ConvertSectoDay(time_activity[activeIndex].value)}`}</b></p>
        </div>
    </div>
    <div style={{ width: '440px', textAlign: 'left',marginTop: '20px', fontSize: '12px'}}>
        <h1 style={{textAlign: 'left', margin: '0px 20px', lineHeight: '2em'}}>
            Consumption per day in week
        </h1>
        <p>&nbsp;</p>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart width={'100%'} height={300} data={day_activity.map((d, i)=> {
            return {day: WEEK_DAYS[i], value: d}
          }) }>
            <XAxis dataKey="day" interval={0} angle={-25} dx={-14} dy={10}/>
            <YAxis/>
            <Bar dataKey="value" onClick={handleClick} stroke={'#ffae55'} fill={'#ffae55'} background={{ fill: '#eee' }}  label={customLable} />
            <CartesianGrid strokeDasharray="3 3" />
          </BarChart>
          </ResponsiveContainer>
    </div>
    </div>
)
}