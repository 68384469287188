import React, {useEffect, useRef} from 'react'


export default function URVideo({time, setTimeFunc, selectedMedia }) {
    const videoRef = useRef();
    const onPlaying = () => {
        if(Math.round(videoRef.current.currentTime) !== Math.round(time)){
        setTimeFunc(videoRef.current.currentTime);
          }
    };

    useEffect(() => {
        if(Math.round(videoRef.current.currentTime) !== Math.round(time)){
            videoRef.current.currentTime = time;
        }
    }, [time])

    return(
        <div style={{background: '#110936', width: 1200, margin: '30px auto', display: 'flex', justifyContent: 'flex-start'}}>
            <figure style={{marginInlineEnd: 10, marginTop: 56}}>
            <video style={{maxWidth: '100%', marginBottom: 0}} poster={`./${selectedMedia}-poster.png`} ref={videoRef} src={`./${selectedMedia}.mp4`} onTimeUpdate={onPlaying} controls={true}/>
            </figure>
            <div style={{paddingRight: 10}}>
                <img src={`./${selectedMedia}-text.png`} alt="ur" width='456' />
            </div>
        </div>
    )
}
