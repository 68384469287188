export default function Data() {
    return [
        {
            "time": 0,
            "count": 2230,
            "avg": 3157,
            "top": 4084,
        },
        {
            "time": 20,
            "count": 1352,
            "avg": 1762,
            "top": 2172
        },
        {
            "time": 40,
            "count": 1302,
            "avg": 1673,
            "top": 2043
        },
        {
            "time": 60,
            "count": 1273,
            "avg": 1618,
            "top": 1962
        },
        {
            "time": 80,
            "count": 1246,
            "avg": 1591,
            "top": 1936
        },
        {
            "time": 100,
            "count": 1231,
            "avg": 1574,
            "top": 1916
        },
        {
            "time": 120,
            "count": 1235,
            "avg": 1558,
            "top": 1880
        },
        {
            "time": 140,
            "count": 1268,
            "avg": 1565,
            "top": 1862
        },
        {
            "time": 160,
            "count": 1250,
            "avg": 1549,
            "top": 1847
        },
        {
            "time": 180,
            "count": 1246,
            "avg": 1537,
            "top": 1828
        },
        {
            "time": 200,
            "count": 1244,
            "avg": 1535,
            "top": 1825
        },
        {
            "time": 220,
            "count": 1245,
            "avg": 1532,
            "top": 1818
        },
        {
            "time": 240,
            "count": 1237,
            "avg": 1526,
            "top": 1814
        },
        {
            "time": 260,
            "count": 1211,
            "avg": 1516,
            "top": 1821
        },
        {
            "time": 280,
            "count": 1200,
            "avg": 1523,
            "top": 1845
        },
        {
            "time": 300,
            "count": 1204,
            "avg": 1538,
            "top": 1871
        },
        {
            "time": 320,
            "count": 1182,
            "avg": 1511,
            "top": 1839
        },
        {
            "time": 340,
            "count": 1194,
            "avg": 1505,
            "top": 1815
        },
        {
            "time": 360,
            "count": 1175,
            "avg": 1492,
            "top": 1809
        },
        {
            "time": 380,
            "count": 1173,
            "avg": 1486,
            "top": 1798
        },
        {
            "time": 400,
            "count": 1170,
            "avg": 1470,
            "top": 1770
        },
        {
            "time": 420,
            "count": 1180,
            "avg": 1486,
            "top": 1791
        },
        {
            "time": 440,
            "count": 1151,
            "avg": 1451,
            "top": 1750
        },
        {
            "time": 460,
            "count": 1141,
            "avg": 1458,
            "top": 1774
        },
        {
            "time": 480,
            "count": 1133,
            "avg": 1438,
            "top": 1742
        },
        {
            "time": 500,
            "count": 1125,
            "avg": 1435,
            "top": 1744
        },
        {
            "time": 520,
            "count": 1130,
            "avg": 1429,
            "top": 1727
        },
        {
            "time": 540,
            "count": 1104,
            "avg": 1412,
            "top": 1719
        },
        {
            "time": 560,
            "count": 1095,
            "avg": 1397,
            "top": 1698
        },
        {
            "time": 580,
            "count": 1107,
            "avg": 1394,
            "top": 1680
        },
        {
            "time": 600,
            "count": 1100,
            "avg": 1388,
            "top": 1675
        },
        {
            "time": 620,
            "count": 1090,
            "avg": 1375,
            "top": 1659
        },
        {
            "time": 640,
            "count": 1070,
            "avg": 1355,
            "top": 1639
        },
        {
            "time": 660,
            "count": 1065,
            "avg": 1352,
            "top": 1639
        },
        {
            "time": 680,
            "count": 1072,
            "avg": 1347,
            "top": 1622
        },
        {
            "time": 700,
            "count": 1057,
            "avg": 1341,
            "top": 1624
        },
        {
            "time": 720,
            "count": 1051,
            "avg": 1331,
            "top": 1611
        },
        {
            "time": 740,
            "count": 1041,
            "avg": 1323,
            "top": 1605
        },
        {
            "time": 760,
            "count": 1036,
            "avg": 1297,
            "top": 1558
        },
        {
            "time": 780,
            "count": 1026,
            "avg": 1286,
            "top": 1545
        },
        {
            "time": 800,
            "count": 1034,
            "avg": 1286,
            "top": 1537
        },
        {
            "time": 820,
            "count": 1027,
            "avg": 1281,
            "top": 1534
        },
        {
            "time": 840,
            "count": 1045,
            "avg": 1289,
            "top": 1532
        },
        {
            "time": 860,
            "count": 1042,
            "avg": 1275,
            "top": 1508
        },
        {
            "time": 880,
            "count": 1041,
            "avg": 1266,
            "top": 1491
        },
        {
            "time": 900,
            "count": 1049,
            "avg": 1270,
            "top": 1490
        },
        {
            "time": 920,
            "count": 1050,
            "avg": 1266,
            "top": 1482
        },
        {
            "time": 940,
            "count": 1036,
            "avg": 1263,
            "top": 1490
        },
        {
            "time": 960,
            "count": 1027,
            "avg": 1256,
            "top": 1484
        },
        {
            "time": 980,
            "count": 1055,
            "avg": 1264,
            "top": 1473
        },
        {
            "time": 1000,
            "count": 1054,
            "avg": 1263,
            "top": 1471
        },
        {
            "time": 1020,
            "count": 1025,
            "avg": 1241,
            "top": 1456
        },
        {
            "time": 1040,
            "count": 1007,
            "avg": 1227,
            "top": 1446
        },
        {
            "time": 1060,
            "count": 1007,
            "avg": 1216,
            "top": 1425
        },
        {
            "time": 1080,
            "count": 1007,
            "avg": 1215,
            "top": 1422
        },
        {
            "time": 1100,
            "count": 984,
            "avg": 1196,
            "top": 1408
        },
        {
            "time": 1120,
            "count": 990,
            "avg": 1196,
            "top": 1402
        },
        {
            "time": 1140,
            "count": 995,
            "avg": 1207,
            "top": 1418
        },
        {
            "time": 1160,
            "count": 982,
            "avg": 1190,
            "top": 1398
        },
        {
            "time": 1180,
            "count": 716,
            "avg": 1025,
            "top": 1334
        }
    ]
}
